<template>
  <div class="constructionAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'施工协议详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-tabs
        v-model="active"
        sticky
        @change="tabChange"
      >
        <van-tab title="基本信息">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBox"
              @scroll="onScroll"
            >
              <van-collapse
                v-model="activeNames"
                accordion
              >
                <van-collapse-item
                  title="基本信息"
                  name="1"
                >
                  <van-cell
                    center
                    title="合同号:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="$router.push({
                          name: 'agreementAddressInfo',
                          params: {
                            id: constructionInfo.businessAgreementId,
                            Refresh: true
                          }
                        })"
                        v-if="constructionInfo.agreementCode && constructionInfo.businessAgreementId"
                      >
                        {{ constructionInfo.agreementCode }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="施工协议号:"
                    :value="constructionInfo.constructionCode || '--'"
                  />
                  <van-cell
                    center
                    title="委托单位:"
                    :value="constructionInfo.entrustCompany || '--'"
                  />
                  <van-cell
                    center
                    title="合同状态:"
                  >
                    <template #default>
                      <span v-if="constructionInfo.status === 0">未开始</span>
                      <span
                        style="color: #F59A23"
                        v-else-if="constructionInfo.status === 1"
                      >实施中</span>
                      <span
                        style="color: #108EE9"
                        v-else-if="constructionInfo.status === 2"
                      >已完成</span>
                      <span
                        style="color: #059B4A"
                        v-else-if="constructionInfo.status === 3"
                      >已验收</span>
                      <span
                        style="color: #D9001B"
                        v-else-if="constructionInfo.status === 4"
                      >已终止</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="单位地址:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="addressHandler(constructionInfo.companyAddress, constructionInfo.entrustCompany, '单位地址')"
                        v-if="constructionInfo.companyAddress
                        "
                        class="ellipsis"
                      >
                        {{ constructionInfo.companyAddress }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <userJump
                    title="施工负责人:"
                    :userList="constructionInfo.constructionHead"
                  ></userJump>
                  <van-cell
                    center
                    title="委托人:"
                    :value="constructionInfo.entrustUserName || '--'"
                  />
                  <van-cell
                    title="联系方式（甲方）:"
                    center
                  >
                    <template #default>
                      <p
                        :style="{ color: '#1990ff' }"
                        v-if="constructionInfo.firstContact"
                        @click="callOut(constructionInfo.firstContact)"
                      >
                        {{ constructionInfo.firstContact }}
                      </p>
                      <p v-else>--</p>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="签订日期:"
                    :value="constructionInfo.signDatetime | openYmdFormat"
                  />
                  <userJump
                    title="签订人:"
                    :userList="constructionInfo.signUser"
                  ></userJump>
                </van-collapse-item>
                <van-collapse-item
                  title="施工建筑信息"
                  name="2"
                >
                  <van-cell
                    center
                    title="项目名称:"
                    :value="constructionInfo?.building?.projectName || '--'"
                  />
                  <van-cell
                    center
                    title="项目建筑:"
                    :value="constructionInfo?.building?.name || '--'"
                  />
                  <van-cell
                    center
                    title="施工建筑:"
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        @click="active = 3"
                        v-if="constructionInfo?.building?.buildingList?.length"
                      >点击查看</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
                <van-collapse-item
                  title="施工单位信息"
                  name="3"
                >
                  <van-cell
                    center
                    title="联网单位:"
                    :value="constructionInfo?.internetCompany?.name || '--'"
                  />
                  <van-cell
                    center
                    title="省/市/区:"
                    :value="(constructionInfo?.internetCompany?.location?.province ? constructionInfo.internetCompany.location.province : '') +
                      (constructionInfo?.internetCompany?.location?.city ? '/' + constructionInfo.internetCompany.location.city : '') +
                      (constructionInfo?.internetCompany?.location?.district
                        ? '/' + constructionInfo.internetCompany.location.district
                        : '') || '--'
                      "
                  />
                  <van-cell
                    center
                    title="施工地址:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="addressHandler(constructionInfo.internetCompany.location.province + constructionInfo.internetCompany.location.city + constructionInfo.internetCompany.location.district + constructionInfo.internetCompany.location.address
                          , constructionInfo.internetCompany.name, '施工地址'
                        )"
                        v-if="constructionInfo?.internetCompany?.location?.address
                        "
                        class="ellipsis"
                      >
                        {{ constructionInfo.internetCompany.location.address }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="系统类型:"
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        @click="
                          clickDialogHandler('系统类型', constructionInfo.internetCompany.systemType.toString())"
                        v-if="constructionInfo?.internetCompany?.systemType"
                      >点击查看</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
                <van-collapse-item
                  title="其它信息"
                  name="4"
                >
                  <van-cell
                    center
                    title="合同份数（甲方）:"
                    :value="constructionInfo?.other?.firstAgreementNumber || '--'"
                  />
                  <van-cell
                    center
                    title="合同份数（乙方）:"
                    :value="constructionInfo?.other?.secondAgreementNumber || '--'"
                  />
                  <van-cell
                    center
                    title="补充说明:"
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        @click="
                          clickDialogHandler('补充说明', constructionInfo.other.description)"
                        v-if="constructionInfo?.other?.description"
                      >点击查看</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="合同费用">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxYyInfo"
              @scroll="onScrollYyInfo"
            >
              <van-collapse
                v-model="activeIntNames"
                accordion
              >
                <van-collapse-item
                  title="服务范围"
                  name="1"
                >
                  <van-cell
                    center
                    title="服务期限:"
                    class="more"
                  >
                    <template #default>
                      <div v-if="constructionInfo?.fee?.serviceDatetimeStart && constructionInfo?.fee?.serviceDatetimeEnd">
                        <span>{{ constructionInfo.fee.serviceDatetimeStart | openYmdFormat }}</span> 到 <span>{{
                          constructionInfo.fee.serviceDatetimeEnd | openYmdFormat }}</span>
                      </div>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="服务时间:"
                    :value="(constructionInfo?.fee?.serviceDay || constructionInfo?.fee?.serviceDay === 0) ? constructionInfo.fee.serviceDay + '天' :
                      '--'
                      "
                  />
                  <van-cell
                    center
                    title="配件质保期限:"
                    class="more"
                  >
                    <template #default>
                      <div v-if="constructionInfo?.fee?.accessoryDatetimeStart && constructionInfo?.fee?.accessoryDatetimeEnd">
                        <span>{{ constructionInfo.fee.accessoryDatetimeStart | openYmdFormat }}</span> 到 <span>{{
                          constructionInfo.fee.accessoryDatetimeEnd | openYmdFormat }}</span>
                      </div>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="配件质保时间:"
                    :value="(constructionInfo?.fee?.accessoryDay || constructionInfo?.fee?.accessoryDay === 0) ? constructionInfo.fee.accessoryDay + '天' :
                      '--'
                      "
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="合同费用"
                  name="2"
                >
                  <van-cell
                    center
                    title="合同原价:"
                    :value="constructionInfo?.fee?.originalCost | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="缴费方式:"
                    :value="(constructionInfo?.fee?.payType) || '--'
                      "
                  />
                  <van-cell
                    center
                    title="法定税率:"
                    :value="(constructionInfo?.fee?.taxRate) || '--'
                      "
                  />
                  <van-cell
                    center
                    title="优惠金额:"
                  >
                    <template #default>
                      <span>{{
                        constructionInfo?.fee?.allPreferential | moneyFormat
                      }}</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="合同总价（含税）:"
                    :value="constructionInfo?.fee?.allMoneyHasTax | moneyFormat
                      "
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="折扣"
                  name="3"
                  v-if="constructionInfo?.fee?.discount"
                >
                  <van-cell
                    center
                    title="优惠费用:"
                    :value="constructionInfo.fee.discount.fee ? `${constructionInfo.fee.discount.fee}%` : '--'
                      "
                  />
                  <van-cell
                    center
                    title="优惠原因:"
                    class="more"
                    :value="constructionInfo?.fee?.discount?.reason.toString() || '--'
                      "
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="优惠"
                  name="4"
                  v-if="constructionInfo?.fee?.preferential"
                >
                  <van-cell
                    center
                    title="优惠费用:"
                    :value="constructionInfo.fee.preferential.fee | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="优惠原因:"
                    class="more"
                    :value="constructionInfo?.fee?.preferential?.reason?.toString() || '--'
                      "
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="减免"
                  name="5"
                  v-if="constructionInfo?.fee?.derate"
                >
                  <van-cell
                    center
                    title="优惠费用:"
                    :value="constructionInfo.fee.derate.fee | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="优惠原因:"
                    class="more"
                    :value="constructionInfo?.fee?.derate?.reason?.toString() || '--'
                      "
                  />
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="附件">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxXfInfo"
              @scroll="onScrollXfInfo"
            >
              <van-collapse
                v-model="activeOpeNames"
                accordion
              >
                <van-collapse-item
                  title="附件信息"
                  name="1"
                >
                  <van-cell
                    center
                    class="more"
                    title="协议原件:"
                    :url="constructionInfo?.files?.agreementScriptFile?.originalPath ? `/api/file${constructionInfo.files.agreementScriptFile.originalPath}` : ''"
                    :value="constructionInfo?.files?.agreementScriptFile?.name || '--'"
                  >
                    <template #default>
                      <span
                        style="color:#1990ff"
                        v-if="constructionInfo?.files?.agreementScriptFile?.name"
                      >{{
                        `${constructionInfo.files.agreementScriptFile.name}.${constructionInfo.files.agreementScriptFile.ext}
                        `}}</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    class="more"
                    title="报价单:"
                    :is-link="constructionInfo?.files?.quotationFile?.length > 1"
                    :url="constructionInfo?.files?.quotationFile && constructionInfo.files.quotationFile.length === 1 ? `/api/file${constructionInfo.files.quotationFile[0].originalPath}` : ''"
                  >
                    <template #default>
                      <span
                        style="color:#1990ff"
                        v-if="constructionInfo?.files?.quotationFile && constructionInfo.files.quotationFile.length === 1"
                      >{{
                        `${constructionInfo.files.quotationFile[0].name}.${constructionInfo.files.quotationFile[0].ext}
                        `}}</span>
                      <span
                        style="color: #1990ff"
                        v-else-if="constructionInfo?.files?.quotationFile"
                        @click="fileHandler(constructionInfo.files.quotationFile, '报价单')"
                      >{{
                        constructionInfo.files.quotationFile.length }}</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="其它附件:"
                    is-link
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        v-if="constructionInfo?.files?.otherFiles"
                        @click="fileHandler(constructionInfo.files.otherFiles, '其它附件')"
                      >{{
                        constructionInfo.files.otherFiles.length }}</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="施工建筑">
          <div
            v-if="constructionInfo?.building?.buildingList?.length"
            class='scrollBox'
          >
            <div class="searchBar">
              <span class="label">共{{ constructionInfo.building.buildingList.length }}条</span>
            </div>
            <van-cell
              v-for="
            (item,
              index)
            in
                constructionInfo.building.buildingList"
              :key="index"
              :label="`建筑地址:${item.address}`"
            >
              <template #title>
                <span
                  class="custom-title"
                  style="margin-right: 10px;vertical-align: middle;"
                >{{ item.name }}</span>
                <van-tag
                  type="danger"
                  v-if="item.isSuperHighRise === 1"
                >超高</van-tag>
              </template>
            </van-cell>
          </div>

          <div
            class="empty"
            v-else
          >
            <van-empty
              image="search"
              description="暂无相关数据"
            />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 其它弹框 -->
    <van-dialog
      v-model="fileShow"
      :title="fileTitle"
      show-cancel-button
      :show-confirm-button="false"
      cancel-button-color="#1989FA"
      class="fileDialog"
    >
      <van-cell
        :title="`${item.name}.${item.ext}`"
        title-style="color:#1989FA"
        v-for="(item, index) in fileArr"
        :key="index"
        @click="
          fileClick(
            item.ext,
            item
          )
          "
        :url="item.ext === 'pdf' ? '/api/file' + item.originalPath : ''"
      />
    </van-dialog>
  </div>
</template>

<script>
import { Dialog, ImagePreview } from 'vant'
import wx from 'weixin-js-sdk'
import index from '@/mixins'
import { getconstructionInfo } from '@/api/construction'
import dayjs from 'dayjs'

export default {
  name: 'constructionAddressInfo',
  mixins: [index],
  data() {
    return {
      activeNames: '1',
      activeIntNames: '1',
      activeOpeNames: '1',
      constructionInfo: {},
      loadingShow: false,
      active: 0,
      top: 0,
      topYyInfo: 0,
      topXfInfo: 0,
      fileShow: false,
      fileArr: [],
      fileTitle: ''
    }
  },
  activated() {
    switch (this.active) {
      case 0:
        if (this.$refs.scrollBox) {
          this.$refs.scrollBox.scrollTop = this.top
        }
        break
      case 1:
        if (this.$refs.scrollBoxYyInfo) {
          this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
        }
        break
      case 2:
        if (this.$refs.scrollBoxXfInfo) {
          this.$refs.scrollBoxXfInfo.scrollTop = this.topXfInfo
        }
        break
    }
    if (
      this.$route.params.Refresh
    ) {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getconstructionInfo(this.$route.params.id)
    } else {
      if (JSON.stringify(this.constructionInfo) === '{}') {
        this.getconstructionInfo(this.$route.params.id)
      }
    }
  },
  methods: {
    // 获取详情
    async getconstructionInfo(id) {
      this.loadingShow = true
      try {
        const { data } = await getconstructionInfo(id)
        if (data.data) {
          this.constructionInfo = data.data
          this.active = 0
          wx.onMenuShareAppMessage({
            title: (this.constructionInfo?.internetCompany?.name) || '--', // 分享标题
            desc: `合同号:${this.constructionInfo.constructionCode ? this.constructionInfo.constructionCode : '--'
              }\n签订人:${this.constructionInfo?.signUser[0]?.name
                ? this.constructionInfo?.signUser[0]?.name
                : '--'
              }\n签订日期:${this.constructionInfo.signDatetime
                ? dayjs(this.constructionInfo.signDatetime).format('YYYY-MM-DD')
                : '--'
              }`, // 分享描述
            link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
            imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
            enableIdTrans: 1, // 是否开启id转译，不填默认为0
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            error: function (res) {
              alert('暂无分享权限')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
          this.loadingShow = false
        } else {
          this.loadingShow = false
          if (data?.status === 'E00030007') {
            this.$toast.fail({
              message: '暂无相应权限,请联系管理员!',
              duration: 500,
              onOpened: () => {
                this.$router.replace('/')
              }
            })
            return
          }
          this.$toast.fail({
            message: '该施工协议已被删除!!',
            duration: 500
          })
        }
      } catch (error) {
        this.loadingShow = false
        if (
          error?.response?.status === 401
        ) {
          return
        }
        if (error?.message?.includes('timeout')) {
          this.$toast.fail({
            message: '网络不好，请稍后再试!',
            duration: 500
          })
          return
        }
        if (error?.response?.data?.status === 'E00030007') {
          this.$toast.fail({
            message: '暂无相应权限,请联系管理员!',
            duration: 500,
            onOpened: () => {
              this.$router.replace('/')
            }
          })
          return
        }
        this.$toast.fail({
          message: '当前网络异常，请稍后再试!!',
          duration: 500
        })
      }
    },
    // 点击查看弹框
    clickDialogHandler(title, text) {
      Dialog.alert({
        title: title,
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 地址弹框
    addressHandler(text, name, title = '地址') {
      this.addressClickTo(
        text, name)
      Dialog.confirm({
        title: title,
        message: text,
        confirmButtonColor: '#1989FA',
        confirmButtonText: '去定位',
        beforeClose: this.beforeClose
      })
        .then(() => {
          // on close
        })
        .catch(() => {
          // on cancel
        })
    },
    // 联网单位跳转
    async internetCompanyClick(id) {
      if (id) {
        this.$router.push({
          name: 'companyAddressInfo',
          params: {
            Refresh: true,
            id: id
          }
        })
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 人员跳转
    checkPeopleClick(id) {
      this.$router.push(`/personalAddressInfo/${id}`)
    },
    // 其它附件弹框
    fileHandler(newArr, title) {
      this.fileArr = newArr
      this.fileTitle = title
      this.fileShow = true
    },
    // 资质附件点击
    fileClick(ext, itemore) {
      if (ext !== 'pdf') {
        const newArr = this.fileArr
          .filter(item => {
            return item.ext !== 'pdf'
          })
          .map(item1 => {
            return '/api/file' + item1.originalPath
          })
        ImagePreview({
          images: [...newArr],
          closeable: true,
          startPosition: this.fileArr
            .filter(itemFirst => {
              return itemFirst.ext !== 'pdf'
            }).findIndex(itemKey => JSON.stringify(itemKey) === JSON.stringify(itemore))
        })
      }
    },
    // 单位基本信息内容滚动
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 单位联网信息滚动
    onScrollYyInfo(e) {
      this.topYyInfo = e.target.scrollTop
    },
    // 单位开通信息滚动
    onScrollXfInfo(e) {
      this.topXfInfo = e.target.scrollTop
    },

    // tabs标签切换
    tabChange(name) {
      switch (name) {
        case 0:
          if (this.$refs.scrollBox) {
            this.$nextTick(() => {
              this.$refs.scrollBox.scrollTop = this.top
            })
          }
          break
        case 1:
          if (this.$refs.scrollBoxYyInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
            })
          }
          break
        case 2:
          if (this.$refs.scrollBoxXfInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxXfInfo.scrollTop = this.topXfInfo
            })
          }
          break
      }
    },
    // 变更记录点击
    changeCardClick(id, version) {
      this.$router.push({
        name: 'constructionChangeAddressInfo',
        params: {
          id,
          version,
          Refresh: true
        }
      })
    },
    // 呼叫手机
    callOut(val) {
      Dialog.confirm({
        title: '信息提示',
        message: `确认呼叫${val}?`,
        confirmButtonColor: '#1989FA'
      })
        .then(() => {
          window.location.href = `tel:${val}`
        })
        .catch(() => {
          // on cancel
        })
    }
  }

}
</script>

<style lang="scss" scoped>
.constructionAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .empty {
      height: 100%;
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .more {
      .van-cell__title {
        flex: 1;
      }

      .van-cell__value {
        flex: 4;
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: hidden;

      .van-tabs__line {
        background-color: #1989fa;
      }

      .van-sticky--fixed {
        top: 46px;
      }

      >.van-tabs {
        height: 100%;

        >.van-tabs__content {
          height: calc(100% - 44px);
          overflow: hidden;

          >.van-tab__pane {
            height: 100%;

            .scrollBox {
              height: 100%;
              overflow: auto;

              .searchBar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;
                height: 24px;
                padding-bottom: 5px;
                background-color: #eeeeee;

                .label {
                  font-size: 12px;
                  color: #aaaaaa;
                  padding-top: 5px;
                }

                .myIcon {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

          .van-collapse {
            background-color: transparent;

            .van-collapse-item {
              background-color: transparent;

              .van-collapse-item__title {
                background-color: white;
                font-weight: 700;
              }

              .van-collapse-item__wrapper {
                background-color: transparent;

                .van-collapse-item__content {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .changeList {
    .van-cell__title {
      background-color: white;

      >span {
        font-weight: 700;
      }
    }
  }

  .fileDialog {
    padding: 10px;
    padding-bottom: 0;
    max-height: 80%;
    overflow: auto;

    .van-dialog__header {
      text-align: left;
      padding: 0;
    }
  }
}
</style>
